<template>
	<div>
		<b-row class="my-2 mx-2">
			<b-col md="12">
				<CCard>
					<!-- <CCardHeader v-if="" color="primario" text-color="white">
                    <i class="fas fa-user-cog fa-md"></i> <span class="h5">{{usuario}}</span>
                </CCardHeader> -->

					<CCardHeader color="dark" text-color="white">
						<i class="fas fa-user-cog fa-md"></i>
						<span class="h5">{{ usuario }}</span>
					</CCardHeader>

					<CCardBody>
						<CTabs variant="pills" vertical>
							<CTab disabled class="text-center mb-2">
								<template slot="title">
									<b-img
										src="img/perfil.png"
										alt="icono-perfil"
										width="200"
										fluid
									></b-img>
								</template>
							</CTab>
							<div class="text-left mb-5">
								<CTab active>
									<template slot="title">
										<i class="fas fa-user fa-md"></i> Mi información
									</template>
									<b-row>
										<b-col lg="12">
											<b-form-group label="Nombre:" class="mb-2">
												<b-form-input
													disabled
													class="disabled-white"
													size="md"
													v-model="datosPersona.nombre"
												></b-form-input>
											</b-form-group>
										</b-col>
										<b-col lg="12">
											<b-form-group label="Correo electrónico:" class="mb-2">
												<b-form-input
													disabled
													class="disabled-white"
													size="md"
													v-model="datosPersona.correo"
												></b-form-input>
											</b-form-group>
										</b-col>
										<!-- <b-col lg="6">
                                        <b-form-group label="Tipo de Documento:" class="mb-2">
                                            <b-form-input disabled class="disabled-white" size="md" v-model="datosPersona.tipoDocumento"></b-form-input>
                                        </b-form-group>
                                    </b-col> -->
										<!-- <b-col lg="6">
                                        <b-form-group label="Número de Documento:" class="mb-2">
                                            <b-form-input disabled class="disabled-white" size="md" v-model="datosPersona.numeroDocumento"></b-form-input>
                                        </b-form-group>
                                    </b-col> -->

										<b-col lg="12">
											<b-button
												variant="danger"
												class="float-right mt-3"
												@click="cerrarSesion()"
											>
												<i class="fas fa-sign-out-alt fa-md"></i> Cerrar
												Sesión</b-button
											>
										</b-col>
									</b-row>
								</CTab>
								<CTab>
									<template slot="title">
										<i class="fas fa-lock-open fa-md"></i> Cambiar Contraseña
									</template>
									<div class="mt-3">
										<validation-observer
											ref="observer2"
											v-slot="{ handleSubmit }"
										>
											<b-form
												@submit.stop.prevent="handleSubmit(cambiarContrasena)"
											>
												<b-row>
													<b-col lg="6">
														<validation-provider
															name="contraseña actual"
															:rules="{ required: true }"
															v-slot="validationContext"
														>
															<b-form-group
																label="Contraseña actual:"
																class="mb-2"
															>
																<b-form-input
																	type="password"
																	:state="getValidationState(validationContext)"
																	size="md"
																	placeholder="Ingrese contraseña actual"
																	v-model="datosContrasena.contrasenaActual"
																></b-form-input>
																<b-form-invalid-feedback>{{
																	validationContext.errors[0]
																}}</b-form-invalid-feedback>
															</b-form-group>
														</validation-provider>
													</b-col>
													<b-col lg="6">
														<validation-provider
															name="nueva contraseña"
															:rules="{ required: true }"
															v-slot="validationContext"
														>
															<b-form-group
																label="Nueva contraseña:"
																class="mb-2"
															>
																<b-form-input
																	type="password"
																	:state="getValidationState(validationContext)"
																	size="md"
																	placeholder="Ingrese nueva contraseña"
																	v-model="datosContrasena.contrasenaNueva"
																></b-form-input>
																<b-form-invalid-feedback>{{
																	validationContext.errors[0]
																}}</b-form-invalid-feedback>
															</b-form-group>
														</validation-provider>
													</b-col>
													<b-col lg="6">
														<validation-provider
															name="confirmar nueva contraseña"
															:rules="{ required: true }"
															v-slot="validationContext"
														>
															<b-form-group
																label="Confirmar nueva contraseña:"
																class="mb-2"
															>
																<b-form-input
																	type="password"
																	:state="getValidationState(validationContext)"
																	size="md"
																	placeholder="Confirme la nueva contraseña"
																	v-model="datosContrasena.contrasenaConfirmar"
																></b-form-input>
																<b-form-invalid-feedback>{{
																	validationContext.errors[0]
																}}</b-form-invalid-feedback>
															</b-form-group>
														</validation-provider>
													</b-col>
													<b-col class="mt-4 " lg="6">
														<b-button
															:disabled="disabled"
															variant="success"
															class="mr-2 float-left"
															type="submit"
															><i class="fas fa-save fa-md"></i>
															Guardar
														</b-button>
													</b-col>
												</b-row>
											</b-form>
										</validation-observer>
									</div>
								</CTab>
							</div>
						</CTabs>
					</CCardBody>
				</CCard>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import axios from 'axios';
import CONSTANTES from '@/Constantes.js';

export default {
	data() {
		return {
			usuario: '',
			datosPersona: {
				nombre: '',
				correo: '',
			},
			datosContrasena: {
				idUsuario: this.$store.state.user.uid,
				usuario: '',
				contrasenaActual: '',
				contrasenaNueva: '',
				contrasenaConfirmar: '',
			},
			disabled: false,
		};
	},
	methods: {
		getValidationState({ dirty, validated, valid = null }) {
			return dirty || validated ? valid : null;
		},
		obtenerDatosPersona() {
			let me = this;
			axios.defaults.headers.common[
				'Authorization'
			] = `Bearer ${localStorage.token}`;
			axios
				.get(
					CONSTANTES.URL_RUTA_SERVICIOS + 'generales/obtener-datos-persona',
					{
						params: {
							idUsuario: this.$store.state.user.uid,
						},
					}
				)
				.then(function(response) {
					console.log(response.data);
					me.datosPersona.nombre =
						response.data[0].nombre +
						' ' +
						response.data[0].apellidoPaterno +
						' ' +
						response.data[0].apellidoMaterno;
					me.datosPersona.correo = response.data[0].correo;
					//me.datosContrasena.usuario = response.data[0].usuario;
				})
				.catch(function(error) {
					me.swat('error', 'Algo salió mal!');
				});
		},

		cambiarContrasena() {
			let me = this;
			me.disabled = true;
			axios
				.post(
					CONSTANTES.URL_RUTA_SERVICIOS + 'generales/cambiar-contrasena',
					me.datosContrasena,
					{
						headers: {
							Authorization: `Bearer ${localStorage.token}`,
						},
					}
				)
				.then(function(response) {
					let variant = response.data.resultado == 0 ? 'error' : 'success';
					me.swat(variant, response.data.mensaje);
					me.disabled = false;
				})
				.catch(function(error) {
					me.swat('error', 'Algo salió mal!');
					me.disabled = false;
				});
		},

		cerrarSesion() {
			let me = this;
			me.$swal
				.fire({
					title: '¿Estas seguro?',
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: '#d33',
					confirmButtonText: 'Cerrar Sesión',
					cancelButtonColor: '#1276e2',
					cancelButtonText: 'Cancelar',
				})
				.then((result) => {
					if (result.isConfirmed) {
						me.$router.push({
							name: 'Login Cliente',
							params: {
								rsCliente: JSON.parse(localStorage.settings).path,
							},
						});
						localStorage.clear();
					}
				});
		},
		swat(icon, title) {
			this.$swal
				.mixin({
					toast: true,
					showConfirmButton: false,
					position: 'bottom-right',
					timer: 3500,
					timerProgressBar: true,
					didOpen: (toast) => {
						toast.addEventListener('mouseenter', this.$swal.stopTimer);
						toast.addEventListener('mouseleave', this.$swal.resumeTimer);
					},
				})
				.fire({
					icon: icon,
					title: title,
				});
		},
	},

	mounted() {
		if (this.$store.state.user) {
			let user = this.$store.state.user;
			this.datosContrasena.idUsuario = user.uid;
			this.usuario = user.username;
			this.obtenerDatosPersona();
		}
	},
};
</script>

<style>
.text-bold {
	font-weight: bold;
}
</style>
